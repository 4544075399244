import * as types from '../mutation-types';
import JsSIP from 'jssip';
import parsePhoneNumber from 'libphonenumber-js';

const findRecordById = ($state, id) =>
  $state.records.find(record => record.id === Number(id)) || {};

const instances = {};
const sessions = {};

const defaultState = {
  records: [],
  uiFlags: {
    isOpen: false,
    isFetching: false,
    isFetchingItem: false,
    isUpdating: false,
    isCheckoutInProcess: false,
  },
  call: {
    id: null,
    duration: 0,
    tag: null,
    phone: null,
    picture_profile: null,
    status: null,
    direction: null,
    active_start_date: null,
    chat_id: null,
    inbox_name: null,
    mute: false,
  },
  connections: {},
};

export const getters = {
  getAccount: $state => id => {
    return findRecordById($state, id);
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getCallInfo($state) {
    return $state.call;
  },
  countIntances($state) {
    return Object.keys($state.connections).length;
  },
};

export const actions = {
  startSip: async (
    { dispatch, commit },
    { inboxName, id, url, uri, password, realm }
  ) => {
    if (instances[id] && id) {
      return;
    }
    // set remote audio stream (to listen to remote audio)
    // remoteAudio is <audio> element on page
    const audio = new window.Audio();
    const socket = new JsSIP.WebSocketInterface(url);
    const configuration = {
      sockets: [socket],
      uri,
      // authorization_user: user,
      password,
      realm,
      trace_sip: true,
      register: true,
    };
    const ua = new JsSIP.UA(configuration);
    ua.start();
    ua.on('newRTCSession', data => {
      const session = data.session;

      if (session.direction === 'incoming') {
        dispatch('incomingCall', {
          id,
          ua: ua,
          session,
        });
      }

      if (session.connection) {
        console.log('Connection is valid');
        session.connection.addEventListener('addstream', e => {
          console.log('Add stream');
          audio.srcObject = e.stream;
          audio.play();
        });

        session.on('addstream', e => {
          // set remote audio stream (to listen to remote audio)
          // remoteAudio is <audio> element on page
          const remoteAudio = audio;
          remoteAudio.src = window.URL.createObjectURL(e.stream);
          remoteAudio.play();
        });
        session.connection.addEventListener('peerconnection', e => {
          console.log('Peer connection');
          audio.srcObject = e.stream;
          audio.play();
        });
      }

      session.on('addstream', e => {
        console.log(e);
        console.log('addstream');
        const remoteAudio = audio;
        remoteAudio.src = window.URL.createObjectURL(e.stream);
        remoteAudio.play();
      });
      // session.on('ended', (e) => {
      //   console.log(e);
      //   console.log('ended', session);
      //   if (session.status && session.status !== 'terminate') {
      //     dispatch('endCall');
      //   }
      // });
      // session.on('confirmed', (e) => {
      //   console.log(e);
      //   console.log('confirmed');
      //   // dispatch('resetCall');
      // });
      session.on('sending', e => {
        console.log(e);
        console.log('sending');
        dispatch('updateCallStatus', 'sending');
        // dispatch('resetCall');
      });
      session.on('getusermediafailed', e => {
        console.log(e);
        console.log('getusermediafailed');
        // dispatch('resetCall');
      });
      session.on('failed', (e) => {
        console.log(e);
        console.log('failed');
        dispatch('resetCall');
      });
      session.on('accepted', (e) => {
        console.log(e);
        console.log('accepted');
      });
      session.on('confirmed', (e) => {
        console.log(e);
        console.log('confirmed');
        // dispatch('acceptCall');
      });
      session.on('progress', (e) => {
        console.log(e);
        console.log('progress');
        // dispatch('acceptCall');
      });
    });
    ua.on('connected', () => {
      dispatch('updateCallStatus', 'connected');
      instances[id] = {
        ua,
        id,
        inobx_name: inboxName,
        realm,
      };
      dispatch('updateConnectionStatus', { id, status: 'connected' });
    });
    ua.on('disconnected', () => {
      dispatch('updateConnectionStatus', { id, status: 'disconnected' });
    });
    ua.on('registered', () => {
      dispatch('updateConnectionStatus', { id, status: 'registered' });
    });
    ua.on('unregistered', () => {
      dispatch('updateConnectionStatus', { id, status: 'unregistered' });
    });
    ua.on('registrationFailed', e => {
      dispatch('updateConnectionStatus', {
        id,
        status: 'registrationFailed',
        reason: e.message,
      });
    });
  },
  outcomingCall: async ({ commit, dispatch }, callInfo) => {
    console.log('outcomingCall', callInfo);
    let { phone, contact_name, chat_id } = callInfo;
    const keys = Object.keys(instances);
    let id = callInfo.id ?? keys[0];
    if (!id) {
      return;
    }
    let ua = instances[id].ua;
    let inbox_name = instances[id].inbox_name;
    const eventHandlers = {
      failed: () => dispatch('resetCall'),
      ended: () => dispatch('resetCall'),
      confirmed: () => dispatch('updateCallStatus', 'accept'),
      addstream: e => {
        console.log(e);
        console.log('addstream');
        console.log('Add stream (event handlers)');
        audio.srcObject = e.stream;
        audio.play();
      }
    };
    const options = {
      eventHandlers,
      mediaConstraints: { audio: true },
    };
    const parsedNumber = parsePhoneNumber(phone);
    const localNumber =
      parsedNumber && parsedNumber.isValid()
        ? parsedNumber.nationalNumber
        : phone;
    const uri = `sip:${localNumber}@${instances[id].realm}`;
    const session = ua.call(uri, options);
    sessions[id] = session;
    commit(types.default.SET_EXTENSION_CALL, {
      id,
      duration: 0,
      tag: contact_name,
      phone: phone,
      picture_profile: callInfo?.profile_picture,
      status: 'calling',
      direction: 'outcoming',
      inbox_name: inbox_name,
      chat_id: chat_id,
    });

    commit(types.default.SET_EXTENSION_UI_FLAG, {
      isOpen: true,
    });
  },
  incomingCall: async ({ commit, state, rootGetters, dispatch }, callInfo) => {
    console.log('incomingCall', callInfo);
    try {
      const userStatus = rootGetters.getCurrentUserAvailability;

      if (state.call.id || userStatus !== 'online') {
        dispatch('rejectCall');
        return;
      }

      let { phone, contact_name, profile_picture, id, session } = callInfo;

      const instance = instances[id];
      const { inbox_name } = instance;
      sessions[id] = session;

      commit(types.default.SET_EXTENSION_CALL, {
        id,
        duration: 0,
        tag: contact_name,
        phone: phone,
        picture_profile: profile_picture,
        status: 'offer',
        direction: 'incoming',
        inbox_name: inbox_name,
        chat_id: null,
      });

      commit(types.default.SET_EXTENSION_UI_FLAG, {
        isOpen: true,
      });
    } catch (error) {
      throw new Error(error);
    }
  },
  updateCallStatus: ({ commit }, status) => {
    commit(types.default.SET_EXTENSION_CALL, {
      status: status,
    });

    if (status === 'accept') {
      commit(types.default.SET_EXTENSION_CALL, {
        active_start_date: new Date(),
      });
    }
  },
  updateConnectionStatus: ({ commit }, data) => {
    commit(types.default.SET_EXTENSION_CONNECTIONS, data);
  },
  acceptCall: async ({ state, dispatch }) => {
    console.log('acceptCall', state.call);
    if (sessions[state.call.id].direction === 'incoming') {
      const session = sessions[state.call.id];
      if (session) {
        session.answer({ mediaConstraints: { audio: true } });
      }
    }
    dispatch('updateCallStatus', 'accept');
  },
  muteCall: async ({ state, commit }) => {
    console.log('muteCall', state.call);
    const session = sessions[state.call.id];
    if (session) {
      session.mute({ audio: true });
      commit(types.default.SET_EXTENSION_CALL, {
        mute: true,
      });
    }
  },
  unmuteCall: async ({ state, commit }) => {
    console.log('unmuteCall', state.call);
    const session = sessions[state.call.id];
    if (session) {
      session.mute({ audio: false });
      commit(types.default.SET_EXTENSION_CALL, {
        mute: false,
      });
    }
  },
  rejectCall: async ({ state, dispatch }) => {
    console.log('rejectCall', state.call);
    const session = sessions[state.call.id];
    if (session) {
      session.terminate();
      delete sessions[state.call.id];
    }
    dispatch('resetCall');
  },
  endCall: async ({ state, dispatch }) => {
    console.log('endCall', state.call);
    const session = sessions[state.call.id];
    if (session) {
      session.terminate();
      delete sessions[state.call.id];
    }
    dispatch('resetCall');
  },
  resetCall: async ({ state, commit }) => {
    delete sessions[state.call.id];
    commit(types.default.SET_EXTENSION_CALL, {
      id: null,
      duration: 0,
      tag: null,
      phone: null,
      picture_profile: null,
      status: null,
      direction: null,
      active_start_date: null,
      inbox_name: null,
      chat_id: null,
      mute: false,
    });
    commit(types.default.SET_EXTENSION_UI_FLAG, {
      isOpen: false,
    });
  },
  updateExtensionVisible: ({ commit }, { isOpen }) => {
    commit(types.default.SET_EXTENSION_UI_FLAG, {
      isOpen: isOpen,
    });
  },
};

export const mutations = {
  [types.default.SET_EXTENSION_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_EXTENSION_CALL]($state, data) {
    $state.call = {
      ...$state.call,
      ...data,
    };
  },
  [types.default.SET_EXTENSION_CONNECTIONS]($state, data) {
    $state.connections = {
      ...$state.connections,
      ...data,
    };
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
