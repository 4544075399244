<script>
import SoundCalling from 'dashboard/assets/audio/calling.mp3';
import SoundRinging from 'dashboard/assets/audio/ring.mp3';

import Thumbnail from '../../widgets/Thumbnail.vue';
import Draggable from './Draggable.vue';

import Microphone from './icons/Microphone.vue';
import MicrophoneSlash from './icons/MicrophoneSlash.vue';
import VideoIcon from './icons/Video.vue';
import PhoneSlash from './icons/PhoneSlash.vue';
import NumpadVue from './icons/Numpad.vue';
import PhoneTransfer from './icons/PhoneTransfer.vue';
import Phone from './icons/Phone.vue';
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css';

export default {
  components: {
    Draggable,
    Thumbnail,
    Microphone,
    VideoIcon,
    MicrophoneSlash,
    Phone,
    PhoneSlash,
    NumpadVue,
    PhoneTransfer,
    VueTelInput,
  },
  props: {},
  data() {
    return {
      elapsedTime: 0,
      timer: null,
      callingSound: null,
      ringSound: null,
      phoneNumber: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'extension/getUIFlags',
      callInfo: 'extension/getCallInfo',
      inboxes: 'inboxes/getInboxes',
    }),
    isMuted() {
      return this.getCallInfo && this.getCallInfo?.mute;
    },
    username() {
      return this.callInfo.tag ?? this.callInfo.phone ?? '';
    },
    isCallEndDisabled() {
      return [
        'terminate',
        'reject',
        'accept_elsewhere',
        'reject_elsewhere',
      ].includes(this.callInfo.status);
    },
    formattedDuration() {
      const minutes = Math.floor(this.elapsedTime / 60);
      const seconds = this.elapsedTime % 60;
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
        2,
        '0'
      )}`;
    },
  },
  watch: {
    inboxes(newInboxes) {
      newInboxes.forEach(inbox => {
        if (inbox.channel_type === 'Channel::Sip') {
          console.log('inbox.sip_credentials', inbox.sip_credentials);
          if (inbox.sip_credentials) {
            this.startSip({
              inboxName: inbox.name,
              id: inbox.id,
              url: inbox.url,
              uri: inbox.sip_credentials.uri,
              realm: inbox.sip_credentials.uri.split('@')[1],
              password: inbox.sip_credentials.password,
            });
          }
        }
      });
    },
    callInfo(newCallInfo) {
      const { status, direction } = newCallInfo;
      console.log('callInfo', status, direction);
      if (status === 'accept') {
        this.startTimer();
      } else if (status === 'terminate') {
        this.stopTimer();
      }
      if (status === 'sending' && direction === 'outcoming') {
        this.playCalling();
      } else {
        this.stopCalling();
      }

      if (status === 'offer' && direction === 'incoming') {
        this.playRinging();
      } else {
        this.stopRinging();
      }
    },
  },
  methods: {
    startSip({ inboxName, id, url, uri, password, realm, user }) {
      this.$store.dispatch('extension/startSip', {
        inboxName,
        id,
        url,
        uri,
        password,
        realm,
        user,
      });
    },
    startTimer() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      const startDate = this.$store.state.extension.call.active_start_date;
      this.timer = setInterval(() => {
        const now = new Date();
        this.elapsedTime = Math.floor((now - startDate) / 1000);
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
      this.elapsedTime = 0;
    },
    closeExtension() {
      this.$store.dispatch('extension/updateExtensionVisible', {
        isOpen: false,
      });
    },
    acceptCall() {
      this.$store.dispatch('extension/acceptCall');
    },
    startCall() {
      if (!this.phoneNumber) return;
      try {
        this.$store.dispatch('extension/outcomingCall', {
          phone: this.phoneNumber,
          contact_name: '',
          profile_picture: '',
          chat_id: '',
        });
        this.phoneNumber = '';
      } catch (error) {
        useAlert(`${this.$t('WEBPHONE.ERROR_TO_MADE_CALL')}: ${error.message}`);
      }
    },
    rejectCall() {
      this.$store.dispatch('extension/rejectCall');
    },
    endCall() {
      this.$store.dispatch('extension/endCall');
    },
    mute() {
      this.$store.dispatch('extension/muteCall');
    },
    unMute() {
      this.$store.dispatch('extension/unmuteCall');
    },
    playCalling() {
      this.callingSound = new Audio(SoundCalling);
      this.callingSound.loop = true;
      this.callingSound.play();
    },
    stopCalling() {
      if (this.callingSound) {
        this.callingSound.pause();
        this.callingSound.currentTime = 0;
      }
    },
    playRinging() {
      this.ringSound = new Audio(SoundRinging);
      this.ringSound.loop = true;
      this.ringSound.play();
    },
    stopRinging() {
      if (this.ringSound) {
        this.ringSound.pause();
        this.ringSound.currentTime = 0;
      }
    },
  },
};
</script>

<template>
  <Draggable :initial-x="50" :initial-y="50">
    <div
      v-if="uiFlags.isOpen && callInfo.status !== 'offer'"
      class="flex flex-col h-[315px] w-[250px] bg-white dark:bg-slate-900 opacity-90 border border-gray-500 rounded-xl select-none cursor-grab"
    >
      <div class="flex h-[30px] place-content-between items-center">
        <p class="text-xs text-slate-800 dark:text-slate-100 m-0 px-4">
          {{ callInfo.inbox_name }}
        </p>

        <button
          class="relative m-0 px-4 select-none rounded-lg bg-gray-900 text-center align-middle font-sans text-xs font-medium uppercase text-white transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
          data-ripple-light="true"
          @click="closeExtension"
        >
          <span
            class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          >
            <fluent-icon
              icon="dismiss"
              size="14px"
              class="text-slate-800 dark:text-slate-100"
            />
          </span>
        </button>
      </div>
      <div class="flex justify-center align-middle mt-2">
        <Thumbnail
          v-if="callInfo.phone"
          :src="callInfo.picture_profile"
          :username="username"
          status="online"
          should-show-status-always
          size="64px"
        />
      </div>
      <div class="flex flex-col my-6">
        <p
          v-if="callInfo.tag"
          class="text-slate-800 dark:text-slate-100 m-0 text-center"
        >
          {{ callInfo.tag }}
        </p>
        <p v-else class="text-slate-800 dark:text-slate-100 m-0 text-center">
          {{ callInfo.phone }}
        </p>

        <p
          v-if="callInfo.status === 'accept'"
          class="text-lg font-medium text-slate-800 dark:text-slate-100 m-0 text-center"
        >
          {{ formattedDuration }}
        </p>
        <p
          v-if="callInfo.status === 'accept_elsewhere'"
          class="text-lg font-medium text-slate-800 dark:text-slate-100 m-0 text-center"
        >
          {{ $t('WEBPHONE.ACCEPT_ELSEWHERE') }}
        </p>
        <p
          v-if="callInfo.status === 'reject_elsewhere'"
          class="text-lg font-medium text-slate-800 dark:text-slate-100 m-0 text-center"
        >
          {{ $t('WEBPHONE.REJECT_ELSEWHERE') }}
        </p>
        <p
          v-if="callInfo.status === 'terminate'"
          class="text-lg font-medium text-slate-800 dark:text-slate-100 m-0 text-center"
        >
          {{ $t('WEBPHONE.TERMINATE') }}
        </p>
        <p
          v-if="callInfo.status === 'reject'"
          class="text-lg font-medium text-slate-800 dark:text-slate-100 m-0 text-center"
        >
          {{ $t('WEBPHONE.TERMINATE') }}
        </p>
        <p
          v-if="callInfo.status === 'calling'"
          class="text-lg font-medium text-slate-800 dark:text-slate-100 m-0 text-center"
        >
          {{ $t('WEBPHONE.CONNECT_CALLING') }}
        </p>
        <p
          v-if="callInfo.status === 'preaccept'"
          class="text-lg font-medium text-slate-800 dark:text-slate-100 m-0 text-center"
        >
          {{ $t('WEBPHONE.CALLING') }}
        </p>
        <p
          v-if="callInfo.status === 'relaylatency'"
          class="text-lg font-medium text-slate-800 dark:text-slate-100 m-0 text-center"
        >
          {{ $t('WEBPHONE.CALLING') }}
        </p>
      </div>
      <div
        v-if="callInfo.status === 'accept'"
        class="flex justify-center align-center gap-x-5 my-2"
      >
        <span
          v-if="!isMuted"
          v-tooltip="$t('WEBPHONE.MUTE')"
          class="cursor-pointer text-slate-800 dark:text-slate-100 hover:text-slate-700 dark:hover:text-slate-300"
          @click="mute"
        >
          <Microphone :size="20" color="currentColor" />
        </span>
        <span
          v-else
          v-tooltip="$t('WEBPHONE.UNMUTE')"
          class="cursor-pointer text-red-300 hover:text-red-500"
          @click="unMute"
        >
          <MicrophoneSlash :size="20" color="currentColor" />
        </span>
        <span
          v-tooltip="$t('WEBPHONE.TURN_ON_VIDEO')"
          class="cursor-no-drop text-slate-600 dark:text-slate-300"
          :disabled="false"
        >
          <VideoIcon :size="20" color="currentColor" />
        </span>
        <span
          v-tooltip="$t('WEBPHONE.NUMPAD')"
          class="cursor-no-drop text-slate-600 dark:text-slate-300"
        >
          <NumpadVue :size="20" color="currentColor" />
        </span>
        <span
          v-tooltip="$t('WEBPHONE.TRANSFER')"
          class="cursor-no-drop text-slate-600 dark:text-slate-300"
        >
          <PhoneTransfer :size="20" color="currentColor" />
        </span>
      </div>
      <div v-else-if="!callInfo.phone">
        <VueTelInput
          v-model="phoneNumber"
          mode="international"
          placeholder="Informe um número de telefone"
          class="reset-base border bg-slate-25 dark:bg-slate-900 ring-offset-ash-900 border-slate-50 dark:border-slate-700/50 w-full disabled:text-slate-200 dark:disabled:text-slate-700 disabled:cursor-not-allowed text-slate-800 dark:text-slate-50 px-1.5 py-1 text-sm rounded-xl h-10"
          @enter="startCall"
        />
      </div>
      <div class="flex justify-center align-center my-4">
        <button
          v-if="['sending', 'accept'].includes(callInfo.status)"
          class="relative h-15 max-h-[30px] w-15 max-w-[30px] p-[17px] select-none rounded-full bg-red-500 text-center align-middle font-sans text-xs font-medium uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
          data-ripple-light="true"
          :disabled="isCallEndDisabled"
          @click="endCall"
        >
          <span
            class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          >
            <PhoneSlash :size="15" color="white" />
          </span>
        </button>
        <button
          v-else-if="!callInfo.status"
          class="relative h-15 max-h-[30px] w-15 max-w-[30px] p-[17px] select-none rounded-full bg-green-500 text-center align-middle font-sans text-xs font-medium uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
          data-ripple-light="true"
          @click="startCall"
        >
          <span
            class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          >
          <Phone :size="15" color="white" />
          </span>
        </button>
      </div>
    </div>
    <div
      v-if="uiFlags.isOpen && callInfo.id && callInfo.status === 'offer'"
      class="flex flex-col h-[315px] w-[250px] bg-white dark:bg-slate-900 opacity-90 border border-gray-500 rounded-xl select-none cursor-grab"
    >
      <div class="flex h-[30px] place-content-between items-center">
        <p class="text-xs text-slate-800 dark:text-slate-100 m-0 px-4">
          {{ callInfo.inbox_name }}
        </p>

        <button
          class="relative m-0 px-4 select-none rounded-lg bg-gray-900 text-center align-middle font-sans text-xs font-medium uppercase text-white transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
          data-ripple-light="true"
          @click="closeExtension"
        >
          <span
            class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          >
            <fluent-icon
              icon="dismiss"
              size="14px"
              class="text-slate-800 dark:text-slate-100"
            />
          </span>
        </button>
      </div>
      <div class="flex justify-center align-middle mt-2">
        <Thumbnail
          :src="callInfo.picture_profile"
          :username="username"
          status="online"
          should-show-status-always
          size="64px"
        />
      </div>
      <div class="flex flex-col my-6">
        <p
          v-if="callInfo.tag"
          class="text-slate-800 dark:text-slate-100 m-0 text-center"
        >
          {{ callInfo.tag }}
        </p>
        <p v-else class="text-slate-800 dark:text-slate-100 m-0 text-center">
          {{ $t('WEBPHONE.UNKNOWN') }}
        </p>

        <p
          class="text-lg font-medium text-slate-800 dark:text-slate-100 m-0 text-center"
        >
          {{ callInfo.phone }}
        </p>
        <p
          v-if="callInfo.status === 'terminate'"
          class="text-lg font-medium text-slate-800 dark:text-slate-100 m-0 text-center"
        >
          {{ $t('WEBPHONE.TERMINATE') }}
        </p>
        <p
          v-if="callInfo.status === 'reject'"
          class="text-lg font-medium text-slate-800 dark:text-slate-100 m-0 text-center"
        >
          {{ $t('WEBPHONE.TERMINATE') }}
        </p>
        <p
          v-if="callInfo.status === 'calling'"
          class="text-lg font-medium text-slate-800 dark:text-slate-100 m-0 text-center"
        >
          {{ $t('WEBPHONE.CONNECT_CALLING') }}
        </p>
        <p
          v-if="callInfo.status === 'preaccept'"
          class="text-lg font-medium text-slate-800 dark:text-slate-100 m-0 text-center"
        >
          {{ $t('WEBPHONE.CALLING') }}
        </p>
        <p
          v-if="callInfo.status === 'relaylatency'"
          class="text-lg font-medium text-slate-800 dark:text-slate-100 m-0 text-center"
        >
          {{ $t('WEBPHONE.CALLING') }}
        </p>
      </div>
      <div class="flex justify-center align-center my-4 gap-x-4">
        <button
          class="relative h-15 max-h-[30px] w-15 max-w-[30px] p-[17px] select-none rounded-full bg-green-500 text-center align-middle font-sans text-xs font-medium uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
          data-ripple-light="true"
          :disabled="isCallEndDisabled"
          @click="acceptCall"
        >
          <span
            class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          >
            <Phone :size="15" color="white" />
          </span>
        </button>
        <button
          class="relative h-15 max-h-[30px] w-15 max-w-[30px] p-[17px] select-none rounded-full bg-red-500 text-center align-middle font-sans text-xs font-medium uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
          data-ripple-light="true"
          :disabled="isCallEndDisabled"
          @click="rejectCall"
        >
          <span
            class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          >
            <PhoneSlash :size="15" color="white" />
          </span>
        </button>
      </div>
    </div>
  </Draggable>
</template>
